import fetch from 'node-fetch';
import React from 'react';
import { RouterProps } from 'react-router';
import './App.css';
import { Quote, ResponseQuote } from './models';

type PayloadResponse<T> = { status: 'failure'; error: T } | { status: 'success'; data: T };

type Props = RouterProps & { quoteId?: string };
type State =
  | { isLoading: true }
  | ({ isLoading: false } & ({ hasError: true; error: FetchError } | { hasError: false; quote: Quote }));

type FetchError = { type: 'doesNotExist' | 'unknown'; data?: unknown };

class App extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = { isLoading: true };
  }

  componentDidMount(): void {
    const urlParts = window.location.href.split('/');
    const urlQuoteId = urlParts.length === 0 ? undefined : urlParts[urlParts.length - 1];
    const quoteId = Number.parseInt(urlQuoteId ?? '');

    this.getData(quoteId);
  }

  async getData(quoteId?: number): Promise<void> {
    const hasQuoteId = quoteId === 0 || !!quoteId;

    const res = await fetch(`http://ikmaak.software:6969/api/quote/${!hasQuoteId ? 'random' : quoteId}`);
    const payload: PayloadResponse<ResponseQuote> = await res.json();

    if (payload.status === 'failure') {
      console.error({
        status: res.status,
        payload
      });

      this.setState({
        isLoading: false,
        hasError: true,
        error: {
          type: res.status === 404 ? 'doesNotExist' : 'unknown',
          data: payload.error
        }
      });
    } else {
      this.props.history.push(`/${payload.data.id}`);

      this.setState({
        isLoading: false,
        hasError: false,
        quote: {
          ...payload.data,
          date: new Date(payload.data.date)
        }
      });
    }
  }

  render(): JSX.Element {
    let id = '';
    let quoteTitle = '';
    let quoteSubtitle = '';
    let quoteSuffix = '';

    if (!this.state.isLoading) {
      if (this.state.hasError) {
        if (this.state.error.type === 'doesNotExist') {
          id = 'idioot';
          quoteTitle = 'Die quote bestaat niet, droplul.';
          quoteSubtitle = 'Zoek een andere op';
          quoteSuffix = 'of druk op Willekeurige quote.';
        } else {
          id = 'ayy lmao';
          quoteTitle = 'Jeroen zuigt in programmeren.';
          quoteSubtitle = 'www.quotewall.nl';
          quoteSuffix = 'Fix je shit. Check console.';
        }
      } else {
        const { quote } = this.state;
        id = `${quote.id}`;
        quoteTitle = quote.text;
        quoteSubtitle = quote.author;
        quoteSuffix = `${quote.date.getDate()}-${quote.date.getMonth() + 1}-${quote.date.getFullYear()}`;
      }
    }

    return (
      <div className="content">
        {!this.state.isLoading && !this.state.hasError && !this.state.quote.isFirst && (
          <p
            className="previous-button"
            style={{ cursor: 'pointer' }}
            onClick={(): Promise<void> =>
              this.getData(this.state.isLoading || this.state.hasError ? undefined : this.state.quote.id - 1)
            }
          >
            {
              <span role="img" aria-label="right">
                👈
              </span>
            }{' '}
            VORIGE
          </p>
        )}

        <p className="random-button" style={{ cursor: 'pointer' }} onClick={(): Promise<void> => this.getData()}>
          Willekeurige quote
        </p>

        {!this.state.isLoading && !this.state.hasError && !this.state.quote.isLast && (
          <p
            className="next-button"
            style={{ cursor: 'pointer' }}
            onClick={(): Promise<void> =>
              this.getData(this.state.isLoading || this.state.hasError ? undefined : this.state.quote.id + 1)
            }
          >
            VOLGENDE{' '}
            {
              <span role="img" aria-label="right">
                👉
              </span>
            }
          </p>
        )}
        {!this.state.isLoading && (
          <div>
            <div className="quote-container">
              <h1 className="title">{quoteTitle}</h1>
              <p className="subtitle">{`- ${quoteSubtitle} (${quoteSuffix})`}</p>
            </div>
            <p className="quote-id">{id}</p>
          </div>
        )}
      </div>
    );
  }
}

export default App;
